<template>
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col ml-n2">
                                <h4 class="mb-1">
                                    <a>{{$t('partnersWithdrawals.show.purchasePayments')}} <strong> {{item.code}}</strong></a>
                                </h4>
                            </div>
                            <div class="col-auto">
                                <router-link :to="path+'/'+item.id + '/edit'" class="btn btn-sm btn-primary" :title="$t('partnersWithdrawals.show.edit')">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a @click="print(item.id)" class="btn btn-sm btn-primary" :title="$t('partnersWithdrawals.show.Printing')">
                                    <i class="fas fa-print"></i>
                                </a>
                                <a :href="$linkGnirator('/purchasepayments/' + item.id + '/pdf')" download class="btn btn-sm btn-primary" :title="$t('partnersWithdrawals.show.DownloadPDF')">
                                    <i class="fas fa-download"></i>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-credit-card-front"></i>
                            {{$t('partnersWithdrawals.show.PaymentDetails')}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-9">
                                <table class="table table-sm table-nowrap customed-table-small">
                                    <tbody>
                                        <tr>
                                            <th scope="row">{{$t('partnersWithdrawals.show.PaymentNumber')}} </th>
                                            <td>{{item.code}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('partnersWithdrawals.show.PaymentDate')}}</th>
                                            <td>{{$dateFormat(item.date)}}</td>
                                        </tr>
                                        <tr>
                                          <th scope="row">{{$t('partnersWithdrawals.show.Administrator')}}</th>
                                          <td>{{item.user ? item.user.name : '--'}}</td>
                                        </tr>
                                       
                                        <tr>
                                            <th scope="row">{{$t('partnersWithdrawals.show.referenceNumber')}}</th>
                                            <td>{{item.refrance != null? item.refrance : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('partnersWithdrawals.show.ReceiveFrom')}}</th>
                                            <td>
                                              <router-link :to="'/partners/' + item.partner.id" >{{item.partner.name}}</router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('partnersWithdrawals.show.PaymentMethod')}}</th>
                                            <td scope="row">{{item.paymentMethod != null ? item.paymentMethod.name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('partnersWithdrawals.show.Treasury')}}</th>
                                            <td>
                                                <router-link :to="'/safes/' + item.safe.id" v-if="item.safe">{{ item.safe.name}}</router-link>
                                                    {{item.safe ? '' : '--'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-3">
                                <div class="price-show">
                                    <div class="price-content">
                                        <p>{{$t('partnersWithdrawals.show.cash')}}</p>
                                        <h1>{{$moneyFormat(item.cost)}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-user-alt"></i>
                            {{$t('partnersWithdrawals.show.ReceiveFrom')}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <table class="table table-sm table-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">{{$t('partnersWithdrawals.show.customerName')}}</th>
                                    <th scope="col">{{$t('partnersWithdrawals.show.withdrawnAmount')}}</th>
                                    <th scope="col">{{$t('partnersWithdrawals.show.previousBalance')}}</th>
                                    <th scope="col">{{$t('partnersWithdrawals.show.totalBalance')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                      <router-link :to="'/partners/' + item.partner.id"  >{{item.partner.name}}</router-link>
                                    </th>
                                    <td>{{$moneyFormat(item.cost)}} </td>
                                    <td>{{$moneyFormat(item.old_balance)}} </td>
                                    <td>{{$moneyFormat(item.new_balance)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <showFile/>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import showFile from '../elements/show/showFile.vue';

    export default {
        data() {
            return {
                path: '/partnersWithdrawals',
                item: {},

                printInvoice: false,
            }
        },
        mounted() {
            this.getitem();
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id))
                    .then(response => {
                        this.item = response.data;
                    });
            },
            print (id) {
                this.$parent.printedPurchasePayment = this.item;
            }
        },
        components: {
            showFile,
        }
    };

</script>
